import React, { useCallback } from "react";
import { loadFull } from "tsparticles";
import logo from '../ring.png';
import particlesOptions from "./particles.json";
import Particles from "react-particles";

function Home () {

    const particlesInit = useCallback(main => {
        loadFull(main);
    }, [])

    return (
        <div>
            <Particles options={particlesOptions} init={particlesInit}/>
            <header className="App-header">
                <img src={logo} className="App-logo" alt="sci-tech"/>
                <br></br>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Aweso
                </a>
                <a
                    className="App-link"
                    href="https://www.youtube.com/c/CONQUERVICTORY"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    This is HOME PAGE
                </a>
            </header>
        </div>
    );
}

export default Home;
