import React, { useCallback } from 'react';
import Particles from "react-particles";
import { loadFull } from "tsparticles";

import ring from './ring.png';
import sring from './ring1.png';
import xsring from './ring2.png';

import './App.css';

import particlesAmongUs from "./amongus.json";
import Home from './components/Home';


import "./styles.css";
import { Link } from "react-scroll";



function App() {
    const particlesInit = useCallback(main => {
        loadFull(main);
    }, [])

    return (
        <div className="App">
            <Particles options={particlesAmongUs} init={particlesInit}/>
            {/* <header className="App-header"> */}
                {/* <img src={logo2} className="App-logo" alt="sci-tech"/>
                <br></br>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Aweso
                </a>
                <a
                    className="App-link"
                    href="https://www.youtube.com/c/CONQUERVICTORY"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Subscribe?
                </a> */}
            {/* </header> */}

        <header className="nav">
        <nav className="nav__container__actions">
          <ul>
            <li>
              <Link activeClass="active" smooth spy to="about">
                ABOUT
              </Link>
            </li>
            <li>
              <Link activeClass="active" smooth spy to="projects">
                PROJECTS
              </Link>
            </li>
            <li>
              <Link activeClass="active" smooth spy to="blog">
                BLOG
              </Link>
            </li>
            <li>
              <Link activeClass="active" smooth spy to="contact">
                CONTACT ME
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      <section id="about">
        <div>
        ABOUT
        <p>Hello this is Aathan the Awesome </p>
        <div className='image-container'>
        <img src={ring} className="App-logo" alt="sci-tech"/>
        <img src={sring} className="ring" alt="sci-tech"/>
        <img src={xsring} className="xsring" alt="sci-tech"/>
        </div>
        </div>
        </section>
      <section id="projects">PROJECTS</section>
      <section id="blog">BLOG</section>
      <section id="contact">CONTACT ME</section>
            {/* <Home/> */}
        </div>
        
    );
}

export default App;
